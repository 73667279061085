import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import breakpoints from "src/styles/breakpoints"

const StyledImage = styled(GatsbyImage)`
  @media (min-width: ${breakpoints.md}) {
    max-width: 400px;
  }
`
const RecipeImage = (props) => {
  const { gatsbyImageData, description } = props.image
  return <StyledImage image={gatsbyImageData} alt={description} />
}

export default RecipeImage
