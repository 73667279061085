import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import Text from "../Blocks/SmallText"
import RecipeImage from "./RecipeImage"
import breakpoints from "../../styles/breakpoints"

const ContentContainer = styled.div`
  max-width: 50%;
  width: 50%;
  padding: 17px;
  padding-top: 0;

  @media (max-width: ${breakpoints.md}) {
    max-width: 100%;
    width: 100%;
  }
`

const ContentBlock = styled.div`
  color: ${colors.white};
  margin-bottom: 2rem;
  :last-of-type {
    margin-bottom: 0;
  }
  p {
    margin: 1rem 0;
  }
  a {
    color: ${colors.white} !important;
  }
`

const Heading3 = styled.h3`
  ${fonts.workSansBold}
  line-height: 1.176;
  font-size: calc((1.5 - 1) * 1.2vw + 1rem);
  margin-bottom: 2rem;
  color: ${colors.white};
  text-transform: uppercase;

  @media (max-width: ${breakpoints.md}) {
    font-size: calc((1.5 - 1) * 1.2vh + 1rem);
  }
`

const RecipeContentContainer = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
  }
`

const RecipeContentOuterContainer = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding: 3vw 6vw;
  width: 80%;

  @media (max-width: ${breakpoints.md}) {
    width: 100%;
    padding: 0;
  }
`

const ImageContainer = styled.div`
  width: 100%;
`

const RecipeContent = (props) => {
  const { ingredients, instructions, image } = props.data
  return (
    <RecipeContentOuterContainer>
      <RecipeContentContainer>
        <ContentContainer>
          <ImageContainer>
            <RecipeImage image={image} />
          </ImageContainer>
        </ContentContainer>
        <ContentContainer>
          <ContentBlock>
            <Heading3>Ingredients</Heading3>
            <Text content={ingredients} />
          </ContentBlock>
          <ContentBlock>
            <Heading3>Instructions</Heading3>
            <Text content={instructions} />
          </ContentBlock>
        </ContentContainer>
      </RecipeContentContainer>
    </RecipeContentOuterContainer>
  )
}

export default RecipeContent
