import React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import colors from "../../styles/colors"

const SpotifyContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 83.3333%;
  padding-left: 16.6667%;

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
  }
`

const SpotifyOuterContainer = styled.div`
  width: 67%;
  max-width: 1600px;
  margin: 0 auto;

  @media (max-width: ${breakpoints.md}) {
    width: 100%;
    padding: 6vw;
  }
`

const Content = styled.p`
  padding-left: 17px;
  padding-right: 17px;
  padding-bottom: 17px;
  font-size: calc((1 - 1) * 1.2vw + 1rem);
  line-height: 1.6;
  color: ${colors.lightGrey};
`

const Iframe = styled.iframe`
  padding-left: 17px;
  padding-right: 17px;
  padding-bottom: 17px;
`

const SpotifyEmbed = (props) => {
  const link = props.link
  const content = props.content

  return (
    <SpotifyOuterContainer>
      <SpotifyContainer>
        <Iframe src={link} width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></Iframe>
        <Content>
          {content}
        </Content>
      </SpotifyContainer>
    </SpotifyOuterContainer>
  )
}

export default SpotifyEmbed