import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import colors from "../../styles/colors"
import { ReactComponent as PrevChevron } from "../../resources/img/svg/prev-chevron.svg"
import { ReactComponent as NextChevron } from "../../resources/img/svg/next-chevron.svg"
import fonts from "../../styles/fonts"

const ComponentBlock = styled.div`
  width: 100%;
  padding: 3vw 5vw 3vw;
  display: flex;
`

const ChevronBlock = styled.div`
  width: 50%;
`

const RecipeLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 25px;
  color: ${colors.white};
  font-size: calc((1.5 - 1) * 1.2vw + 1rem);
  ${fonts.workSansBold}
  text-decoration: none;
  width: fit-content;
  margin-left: ${(props) => props.left || 'auto'};
  margin-right: ${(props) => props.left && 'auto'};
`

const StyledPrevChevron = styled(PrevChevron)`
  width: 18px;
  height: 32px;
`

const StyledNextChevron = styled(NextChevron)`
  width: 18px;
  height: 32px;
`

const PreviousRecipeBlock = (props) => {
  const { previousRecipe, nextRecipe } = props
  
  return (
    <ComponentBlock>
      <ChevronBlock>
        {previousRecipe ?
        <RecipeLink to={`/content-recipes/${previousRecipe.slug}`} left={true}>
          <StyledPrevChevron />
          {previousRecipe.title}
        </RecipeLink>
        : 
        null}
      </ChevronBlock>
      <ChevronBlock>
        {nextRecipe ?
        <RecipeLink to={`/content-recipes/${nextRecipe.slug}`} left={false}>
          {nextRecipe.title}
          <StyledNextChevron />
        </RecipeLink>
        : 
        null}
      </ChevronBlock>
    </ComponentBlock>
  )
}

export default PreviousRecipeBlock