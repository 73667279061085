import React from "react"
import Layout, {
  MOBILE_HEADER_HEIGHT,
  DESKTOP_HEADER_HEIGHT,
} from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql, Link } from "gatsby"
import RecipeContent from "../components/Recipe/RecipeContent"
import SpotifyEmbed from "../components/Recipe/SpotifyEmbed"
import colors from "../styles/colors"
import styled from "styled-components"
import fonts from "../styles/fonts"
import breakpoints from "../styles/breakpoints"
import ChevronRecipeBlock from "../components/Recipe/ChevronRecipeBlock"
import Text from "../components/Blocks/Text"
 
const PageContainer = styled.div`
  padding-top: calc(4vmax + ${DESKTOP_HEADER_HEIGHT}px);
  padding-left: 5vw;
  padding-right: 5vw;
  padding-bottom: 6.6vmax;

  @media (max-width: ${breakpoints.md}) {
    padding-top: calc(4vmax + ${MOBILE_HEADER_HEIGHT}px);
  }
`

const RecipeTitle = styled.h1`
  line-height: 1.2em;
  color: ${colors.white};
  text-align: center;
  width: 100%;
  margin-bottom: 35px;

  @media (max-width: ${breakpoints.md}) {
    font-size: calc((4 - 1) * 1.2vh + 1rem);
  }
`

const Note = styled.div`
  color: ${colors.white};
  max-width: 560px;
  margin: auto;
  text-align: center;
`

const RecipeCategoryLink = styled(Link)`
  color: ${colors.lightGrey};
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  font-size: calc((0.9 - 1) * 1.2vw + 1rem);
  line-height: 1.4em;
  margin-bottom: 2rem;
  display: block;
`

const RecipeTemplate = (props) => {
  const {
    metaTitle,
    metaDescription,
    title,
    ingredients,
    instructions,
    image,
    spotifyEmbedLink,
    playlistText,
    category,
    position,
    note,
    socialMediaImage
  } = props.data.contentfulRecipePage
  const path = props.location.pathname
  const recipeData = {
    ingredients: ingredients,
    instructions: instructions,
    image: image,
  }

  const otherRecipes = props.data.allContentfulRecipePage.edges

  const getPreviousRecipe = (recipes) => {
    if (recipes[0].node.position === position) {
      return null
    }
    for (let i = 1; i < recipes.length; i++) {
      if (recipes[i].node.position === position) {
        return {
          slug: recipes[i - 1].node.slug,
          title: recipes[i - 1].node.title,
        }
      }
    }
    return null
  }

  const getNextRecipe = (recipes) => {
    if (recipes[recipes.length - 1].node.position === position) {
      return null
    }
    for (let i = 0; i < recipes.length - 1; i++) {
      if (recipes[i].node.position === position) {
        return {
          slug: recipes[i + 1].node.slug,
          title: recipes[i + 1].node.title,
        }
      }
    }
    return null
  }

  const previousRecipe = getPreviousRecipe(otherRecipes)
  const nextRecipe = getNextRecipe(otherRecipes)

  let sharingImage = false
  if (socialMediaImage) {
    sharingImage = socialMediaImage.file.url
  }

  return (
    <Layout bg={colors.royalBlue}>
      <Seo
        title={metaTitle}
        description={metaDescription}
        path={path}
        image={sharingImage}
      />
      <PageContainer>
        <RecipeCategoryLink to={`/content-recipes/category/${category.slug}`}>
          {category.title}
        </RecipeCategoryLink>
        <RecipeTitle>{title}</RecipeTitle>
        {note && (
          <Note>
            <Text content={note} />
          </Note>
        )}
        {spotifyEmbedLink ? (
          <SpotifyEmbed link={spotifyEmbedLink} content={playlistText} />
        ) : (
          <RecipeContent data={recipeData} />
        )}
      </PageContainer>
      <ChevronRecipeBlock
        previousRecipe={previousRecipe}
        nextRecipe={nextRecipe}
      />
    </Layout>
  )
}

export default RecipeTemplate

export const pageQuery = graphql`
  query RecipeTemplateQuery($slug: String!) {
    contentfulRecipePage(slug: { eq: $slug }) {
      id
      metaTitle
      metaDescription
      title
      slug
      socialMediaImage {
        file {
          url
        }
      }
      note {
        raw
      }
      ingredients {
        raw
      }
      instructions {
        raw
      }
      image {
        gatsbyImageData
        description
      }
      spotifyEmbedLink
      playlistText
      category {
        slug
        title
      }
      position
    }
    allContentfulRecipePage(sort: { order: ASC, fields: position }) {
      edges {
        node {
          slug
          title
          position
        }
      }
    }
  }
`
